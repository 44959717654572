<template>
  <div>
    <a-button-group>
      <a-button type="default" size="small" @click="detailModalVisible = true">详情</a-button>
      <a-popconfirm title="确定取消审批吗" :disabled="item.status !== 'approving'" @confirm="cancel">
        <a-button type="danger" :disabled="item.status !== 'approving'" size="small">取消审批</a-button>
      </a-popconfirm>
    </a-button-group>

    <a-modal :visible="detailModalVisible" title="审批详情" :width="720" :footer="null" @cancel="detailModalVisible = false">
      <a-table rowKey="id" size="small" :columns="columns" :dataSource="item.approval_task_items" :pagination="false">
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { approvalOrderCancel } from "@/api/approval";

export default {
  props: ["item"],
  data() {
    return {
      detailModalVisible: false,

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "审批人",
          dataIndex: "approver",
          customRender: (_, item) => item.approver_item.name,
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (_, item) => item.status_display,
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "审批时间",
          dataIndex: "approval_time",
        },
      ],
    };
  },
  methods: {
    cancel() {
      approvalOrderCancel({ id: this.item.id }).then((data) => {
        this.$message.success("取消成功");
        this.$emit("update", data);
      });
    },
  },
};
</script>

<style scoped></style>
